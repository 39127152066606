import request from "@/js/request";
import {baseURL} from "@/js/getawayModule";

/**
 * 获取找回密码短信验证码
 * @param data
 * @returns {AxiosPromise}
 */
export function postFoundCode(data) {
  return request({
    url: `${baseURL.authApi}/getFoundCode`,
    method: 'post',
    data: data
  })
}

/**
 * 短信验证码 重置密码
 * @param data
 * @returns {AxiosPromise}
 */
export function postResetPassWord(data) {
  return request({
    url: `${baseURL.authApi}/resetPassWord`,
    method: 'post',
    data: data
  })
}
