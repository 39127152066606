<template>
  <div class="body-box">
    <nav-box></nav-box>
    <div class="w">
      <div v-show="passStatus === '1'">
        <el-form ref="resForm" :model="form" :rules="loginFormRules" label-width="80px">
          <el-row>
            <el-col :span="24">
              <el-form-item prop="phone" label="手机号码">
                <el-input @keyup.native.enter="nextStep" v-model.trim="form.phone" placeholder="请输入手机号码"></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="24" class="verify">
              <el-form-item prop="code" label="验证码">
                <el-input @keyup.native.enter="nextStep" v-model.trim="form.code" placeholder="请输入验证码"></el-input>
              </el-form-item>
              <span class="pic"><img :src="this.picCode" alt="" @click="variation"/></span>
            </el-col>

            <el-col :span="24" class="login-bottom" style="text-align: center;margin-top: 20px;">
              <el-button @click="nextStep" :loading="disableButton">下一步</el-button>
            </el-col>
          </el-row>
        </el-form>
      </div>

      <div v-show="passStatus === '2'">
        <el-form ref="editFormRef" :model="passForm" :rules="loginPassForm" label-width="100px">
          <el-row>
            <el-col :span="24">
              <el-form-item prop="password" label="新密码">
                <el-input @keyup.native.enter="modifyPassword" v-model.trim="passForm.password" placeholder="请输入新密码" type="password"></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="24">
              <el-form-item prop="password2" label="确定新密码">
                <el-input @keyup.native.enter="modifyPassword" v-model.trim="passForm.password2" placeholder="请输入确定新密码" type="password"></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="24" class="verify">
              <el-form-item prop="code" label="短信验证码">
                <el-input @keyup.native.enter="modifyPassword" v-model.trim="passForm.code" placeholder="请输入短信验证码"></el-input>
              </el-form-item>
              <span class="pic" @click="goAhead">
                <!--<el-button @click="goAhead" class="note-box" :disabled="disable" :class="{ codeGeting:isGeting }">
                {{ getCode }}
              </el-button>-->
                重新获取验证码
                <!--<el-button @click="goAhead"></el-button>-->
              </span>
            </el-col>

            <el-col :span="24" class="login-bottom" style="text-align: center;margin-top: 20px;">
              <el-button @click="goAhead">上一步</el-button>
              <el-button @click="modifyPassword" :loading="disableButton">确认修改</el-button>
            </el-col>
          </el-row>
        </el-form>
      </div>

    </div>
    <qq-footer></qq-footer>
  </div>
</template>

<script>
import NavBox from '@/components/common/navBox'
import QqFooter from '@/components/common/qqFooter'
import { postFoundCode, postResetPassWord } from '@/api/user/user'
export default {
  components: { QqFooter, NavBox },
  data() {
    return {
      picCode: '',
      form: {},
      passForm: {},
      loginFormRules: {
        // 验证用户名是否合法
        phone: [ { required: true, message: '请输入用户名', trigger: 'blur' }, { min: 11, max: 11, message: '请输入合法的手机号', trigger: 'blur' } ],
        code: [ { required: true, message: '验证码错误', trigger: 'blur' }, { min: 4, max: 4, message: '验证码错误', trigger: 'blur' } ]
      },
      loginPassForm: {
        // 验证用户名是否合法
        code: [ { required: true, message: '请输入用户名', trigger: 'blur' }],
        password: [ { required: true, message: '验证码错误', trigger: 'blur' }],
        password1: [ { required: true, message: '验证码错误', trigger: 'blur' }]
      },
      passStatus: '1',
      disableButton: false,
      disable: false,
      isGeting: false,
      getCode: '重新获取验证码',
    }
  },
  created () {
    this.getClod()
    const val = sessionStorage.getItem('passStatus')
    if (val) {
      this.passStatus = val
    }
  },
  methods: {
    //获取验证码
    async getClod () {
      const sn = new Date().getTime()
      sessionStorage.setItem('sn', sn)
      this.picCode = await `${this.getaway.user}auth/getVerifyCode/forget?from=WEB&sn=${sn}`
    },

    //上一步
    goAhead() {
      this.passStatus = '1'
      sessionStorage.setItem('passStatus', this.passStatus)
      this.passForm = {}
    },

    getVerifyCode() {
      var countDown = setInterval(() => {
        if (this.count < 1) {
          this.isGeting = false
          this.disable = false
          this.getCode = '获取验证码'
          this.count = 120
          clearInterval(countDown)
        } else {
          this.isGeting = true
          this.disable = true
          this.getCode = this.count-- + 's后重发'
        }
      }, 1000)
    },

    //刷新验证码
    variation () {
      this.picCode = this.picCode + '&k=' + Math.random()
    },

    //下一步
    nextStep() {
      this.$refs.resForm.validate(async (valid) => {
        if (!valid) return;
        this.disableButton = true
        postFoundCode(this.form).then(res => {
          if (res) {
            this.disableButton = false
          }
          if (res.code === '0') {
            this.$message.success('发送成功');
            this.passStatus = '2'
            sessionStorage.setItem('passStatus', this.passStatus)
          }
        })
      });

    },

    //确认修改
    modifyPassword() {
      this.$refs.editFormRef.validate(async (valid) => {
        if (!valid) return;
        const data = {
          code: this.passForm.code,
          password: this.passForm.password,
          password2: this.passForm.password2,
          username: this.form.phone
        }
        this.disableButton = true
        postResetPassWord(data).then(res =>{
          if (res) {
            this.disableButton = false
          }
          if (res.code === '0') {
            this.$message.success('重置密码成功');
            sessionStorage.removeItem('passStatus')
            this.$router.push('/index')
          }
        })
      });


    }
  }
}
</script>

<style lang="less" scoped>
.w {
  width: 1200px;
  margin: 0 auto;
}
.el-form {
  padding: 100px 200px;
}
/deep/ .verify {
  position: relative;

  .el-input__inner {
    width: 76%;
  }

  .pic {
    position: absolute;
    top: 1px;
    right: 0;
    width: 16%;
    height: 34px;
    line-height: 34px;
    text-align: center;
    border: 1px solid #dcdfe6;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
